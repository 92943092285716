import { projectService } from '../services'
import Vue from 'vue'

const state = {
  loading: false,
  projects: [],
  selectedProjectId: null,
  projectImages: [],
  projectImagesSingle: [],
  loadingProjectImages: false,
  loadingProjectImagesSingle: false,
  allImages: [],
  loadingAllImages: false,
  loadingRejects: false,
  rejects: [],
  stats: [],
  statsLoading: false,
  detailedStats: [],
  detailedStatsLoading: false
}

const actions = {
  async createProject ({ dispatch, commit }, project) {
    commit('setLoading', true)
    try {
      const response = await projectService.create(project)
      commit('setLoading', false)
      dispatch('alert/success', 'Successfully Create a New Project', {
        root: true
      })
      dispatch('getUserProjects')
      commit('setCreatedProject', project)
      return response
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to create project', { root: true })
      return error
    }
  },
  async startTraining ({ dispatch, commit }, project) {
    try {
      const response = await projectService.startTraining(project)
      dispatch('alert/success', 'Preprocessing Complete', {
        root: true
      })
      dispatch('getUserProjects')
      // commit('setCreatedProject', project)
      return response
    } catch (error) {
      dispatch('alert/error', 'Preprocessing Failed', { root: true })
      return error
    }
  },
  async pollTraining ({ dispatch, commit }, project) {
    try {
      const response = await projectService.pollTraining(project)
      dispatch('alert/success', 'Polled Training', {
        root: true
      })
      dispatch('getUserProjects')
      // commit('setCreatedProject', project)
      return response
    } catch (error) {
      dispatch('alert/error', 'Poll Failed', { root: true })
      return error
    }
  },
  async getUserProjects ({ dispatch, commit }) {
    commit('setLoading', true)
    try {
      const projectId = JSON.parse(localStorage.getItem('user')).id
      const projects = await projectService.getUserProjects(projectId)
      commit('setLoading', false)
      commit('setProjects', projects)
      return projects
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to get projects', { root: true })
      return error
    }
  },
  updateProjectInState ({ dispatch, commit }, project) {
    commit('updateProjectInState', project)
  },

  async updateProject ({ dispatch, commit }, project) {
    commit('setLoading', true)

    try {
      const response = await projectService.update(project)
      commit('setLoading', false)
      dispatch('alert/success', 'Successfully Updated Project', { root: true })
      dispatch('getUserProjects')
      return response
    } catch (error) {
      commit('setLoading', false)
      dispatch('alert/error', 'Failed to update project', { root: true })
      return error
    }
  },

  selectProject ({ commit }, projectId) {
    commit('setSelectedProjectId', projectId)
  },

  async getProjectImages2 ({ commit, dispatch }, projectId) {
    try {
      // // // console.log('id', projectId)
      commit('clearProjectImages')
      commit('setProjectImagesLoading', true)
      const images = await projectService.getProjectImages(projectId)
      if (images && images.images) {
        commit('setProjectImages', images.images)
        commit('setProjectImagesLoading', false)
      } else {
        dispatch('alert/warning', 'No images found for this project.', { root: true })
        commit('setProjectImagesLoading', false)
      }
    } catch (error) {
      commit('setProjectImagesLoading', false)
      dispatch('alert/error', 'Failed to get project images', { root: true })
      return error
    }
  },
  async getProjectImages2Single ({ commit, dispatch }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    try {
      // // // console.log('id', projectId)
      commit('clearProjectImagesSingle')
      commit('setProjectImagesLoadingSingle', true)
      const images = await projectService.getProjectImagesSingle(projectId, imgId)
      if (images && images.images) {
        commit('setProjectImagesSingle', images.images)
        commit('setProjectImagesLoadingSingle', false)
      } else {
        dispatch('alert/warning', 'No images found for this project.', { root: true })
        commit('setProjectImagesLoadingSingle', false)
      }
    } catch (error) {
      commit('setProjectImagesLoadingSingle', false)
      dispatch('alert/error', 'Failed to get project images', { root: true })
      return error
    }
  },

  async submitProjectImages ({ commit, dispatch }, params) {
    try {
      commit('setLoading', true)
      await projectService.submitProjectImages(params.projectId, params.payload)
      commit('setLoading', false)
      return null
    } catch (error) {
      return error
    }
  },

  async getAllImages2 ({ commit, dispatch }, projectId) {
    commit('clearAllImages')
    commit('setAllImagesLoading', true)
    try {
      const allImages = await projectService.getAllImages(projectId)
      if (allImages && allImages.images) {
        commit('setAllImages', allImages.images)
        commit('setAllImagesLoading', false)
      } else {
        dispatch('alert/warning', 'No images found in the image library.', { root: true })
        commit('setAllImagesLoading', false)
      }
    } catch (error) {
      // console.log(error)
      dispatch('alert/error', 'Failed to load images', { root: true })
      commit('setAllImagesLoading', false)
      return error
    }
  },

  async getRejections2 ({ commit, dispatch }, projectId) {
    commit('clearRejects')
    commit('setLoadingRejection', true)
    try {
      const allRejects = await projectService.getRejections(projectId)
      // // // console.log('rej image')
      commit('setRejection', allRejects)
    } catch (error) {
      dispatch('alert/error', 'Failed to load rejected images', { root: true })
      commit('setLoadingRejection', false)
      throw new Error(error)
    }
  },

  async assignImageToProject ({ commit, dispatch }, projectId, imageIds) {
    commit('setLoading', true)
    try {
      const assignedImage = await projectService.assignImageToProject({
        projectId,
        imageIds
      })
      commit('setLoading', false)
      await dispatch('getAllImages2', projectId.projectId)
      await dispatch('getProjectImages2', projectId.projectId)
      dispatch('alert/success', 'Image/s assigned to project', {
        root: true
      })

      return assignedImage
    } catch (error) {
      dispatch('alert/error', 'Failed to assign image to project', {
        root: true
      })
      console.error('Failed to assign image to project', error)
      throw new Error(error)
    }
  },

  async removeImageFromProject ({ commit, dispatch }, payload) {
    try {
      const removedImages = await projectService.removeImageFromProject(
        payload.removalIds,
        payload.p_id,
        payload.reason,
        payload.phase
      )
      commit('alert/success', 'Image(s) removed successfully', { root: true })
      await dispatch('getRejections2', payload.p_id)
      return removedImages
    } catch (error) {
      console.error(error)
      commit('alert/error', 'Image(s) removal unsuccessful', { root: true })
      throw new Error(error)
    }
  },
  async getProjectStats ({ dispatch, commit }, payload) {
    commit('setLoadingStats', true)
    try {
      const stats = await projectService.getProjectStats(payload)
      console.log('stats pull1', stats)
      if (stats === null) {
        await dispatch('getUserProjects')
      }
      const input = { stats: stats, projectId: payload }
      commit('setProjectStats', input)
      commit('setLoadingStats', false)
    } catch (error) {
      commit('setLoadingStats', false)
      dispatch('alert/error', 'Failed to get projects stats', { root: true })
      return error
    }
  },
  async getProjectDetailedStats ({ dispatch, commit }, payload) {
    commit('setLoadingDetailedStats', true)
    try {
      const stats = await projectService.getProjectDetailedStats(payload)
      console.log('stats pull2', stats)
      if (stats === null) {
        await dispatch('getUserProjects')
      }
      const input = { stats: stats, projectId: payload }
      commit('setProjectDetailedStats', input)
      commit('setLoadingDetailedStats', false)
    } catch (error) {
      commit('setLoadingDetailedStats', false)
      dispatch('alert/error', 'Failed to get projects stats', { root: true })
      return error
    }
  },
  async updateConfidence ({ dispatch, commit }, payload) {
    try {
      commit('updateConfidence', payload)
    } catch (error) {
      dispatch('alert/error', 'Failed to get update thereshold', { root: true })
      return error
    }
  }
}

const mutations = {
  clearProjectImages (state) {
    state.projectImages = []
  },
  clearProjectImagesSingle (state) {
    state.projectImagesSingle = []
  },
  clearAllImages (state) {
    state.allImages = []
  },
  clearRejects (state) {
    state.rejects = []
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setLoadingStats (state, loading) {
    state.statsLoading = loading
  },
  setProjectStats (state, payload) {
    const curIndex = state.stats.findIndex(p => p.projectId === payload.projectId)
    // console.log('stats pull', curIndex, payload)
    if (curIndex > -1) {
      state.stats[curIndex].stats = payload.stats
    } else {
      const tmp = { stats: payload.stats, projectId: payload.projectId, confiThes: 0.5 }
      state.stats.push(tmp)
    }
  },
  setLoadingDetailedStats (state, loading) {
    state.detailedStatsLoading = loading
  },
  setProjectDetailedStats (state, payload) {
    const curIndex = state.detailedStats.findIndex(p => p.projectId === payload.projectId)
    // // console.log('stats pull', curIndex, payload)
    if (curIndex > -1) {
      state.detailedStats[curIndex].stats = payload.stats
    } else {
      const tmp = { stats: payload.stats, projectId: payload.projectId }
      state.detailedStats.push(tmp)
    }
  },
  updateConfidence (state, payload) {
    // console.log('update theshold', payload)
    const curIndex = state.stats.findIndex(p => p.projectId === payload.projectId)
    // console.log('stats pull', curIndex)
    // console.log('stats pull', curIndex > -1)
    if (curIndex > -1) {
      // console.log('stats pull2', state.stats[curIndex])
      state.stats[curIndex].confiThes = payload.confiThes
    }
  },
  setLoadingRejection (state, loading) {
    state.loadingRejects = loading
  },
  setRejection (state, data) {
    state.rejects = data
    state.loadingRejects = false
  },
  setProjects (state, projects) {
    state.projects = projects
  },
  setCreatedProject (state, project) {
    state.projects.push(project)
  },
  setSelectedProjectId (state, projectId) {
    state.selectedProjectId = projectId
  },
  setProjectImages (state, images) {
    state.projectImages = images
  },
  setProjectImagesSingle (state, images) {
    state.projectImagesSingle = images
  },
  setAllImages (state, allImages) {
    state.allImages = allImages
  },
  setProjectImagesLoading (state, loading) {
    state.loadingProjectImages = loading
  },
  setProjectImagesLoadingSingle (state, loading) {
    state.loadingProjectImagesSingle = loading
  },
  setAllImagesLoading (state, loading) {
    state.loadingAllImages = loading
  },
  updateProjectInState (state, project) {
    const _index = state.projects.findIndex(_project => _project._id === project._id)
    if (_index && _index >= 0) {
      Vue.set(state.projects, _index, project)
    }
  }
}

export const projectSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
